/**
 * Created by rieger on 08.04.17.
 */
import * as moment from 'moment';
import 'moment/locale/de';
import {Moment} from "moment";



class Timesince {

  dom_date: HTMLElement;
  dom_time: HTMLElement;
  since_date: Moment;

  constructor(date_dom_id: string, time_dom_id: string,since_date: any ) {
    this.dom_date = document.getElementById(date_dom_id);
    this.dom_time = document.getElementById(time_dom_id);
    this.since_date  = moment(since_date);
    this.update();
  }

  update() {
    
    let now = moment();

    let years = now.year() - this.since_date.year();
    let months = now.month() - this.since_date.month();
    let days = now.date() - this.since_date.date();
    let hours = now.hour() - this.since_date.hour();
    let minutes = now.minute() - this.since_date.minute();
    let seconds = now.second() - this.since_date.second();

    if (seconds < 0) {
      seconds = 60 + seconds;
      minutes--;
    }
    if (minutes < 0) {
      minutes = 60 + minutes;
      hours--;
    }
    if (hours < 0) {
      hours = 24 + hours;
      days--;
    }
    if (days < 0) {
      let daysInLastFullMonth = moment(now.year() + '-' + (now.month() + 1), "YYYY-MM").subtract(1, 'M').daysInMonth();
      if (daysInLastFullMonth < this.since_date.date()) {
        days = daysInLastFullMonth + days + (this.since_date.date() - daysInLastFullMonth);
      } else {
        days = daysInLastFullMonth + days;
      }
      months--;
    }
    if (months < 0) {
      months = 12 + months;
      years--;
    }

    this.dom_date.textContent = `${years} Jahr` + (years > 1 ? 'e ' : ' ')
     + `${months} Monat` + (months > 1 ? 'e ' : ' ')
     + `${days} Tag`     + (days > 1 ? 'e' : '');

    this.dom_time.textContent = `${hours} Stunde` + (hours != 1 ? 'n ' : ' ')
      + ` ${minutes} Minute`  + (minutes != 1 ? 'n ' : ' ')
      + ` ${seconds} Sekunde` + (seconds != 1 ? 'n ' : ' ');

    setTimeout(() => {
      this.update();
    },1000);
  }
}

new Timesince('lifedate', 'lifetime',  [2014, 9, 1,  10, 47, 22]);


